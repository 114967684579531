exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-iwakura-index-jsx": () => import("./../../../src/pages/iwakura/index.jsx" /* webpackChunkName: "component---src-pages-iwakura-index-jsx" */),
  "component---src-pages-medical-index-jsx": () => import("./../../../src/pages/medical/index.jsx" /* webpackChunkName: "component---src-pages-medical-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-nishiharu-index-jsx": () => import("./../../../src/pages/nishiharu/index.jsx" /* webpackChunkName: "component---src-pages-nishiharu-index-jsx" */),
  "component---src-pages-strengths-index-jsx": () => import("./../../../src/pages/strengths/index.jsx" /* webpackChunkName: "component---src-pages-strengths-index-jsx" */),
  "component---src-pages-tanaka-index-jsx": () => import("./../../../src/pages/tanaka/index.jsx" /* webpackChunkName: "component---src-pages-tanaka-index-jsx" */),
  "component---src-pages-visit-index-jsx": () => import("./../../../src/pages/visit/index.jsx" /* webpackChunkName: "component---src-pages-visit-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */)
}

